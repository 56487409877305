import axios from "utils/axios";

import {
  GENERATE_QR_URL,
  GENERATE_QR_ONLY_TO_PREVIEW_URL,
  GET_ALL_QR_CODE_URL,
  GET_ALL_QR_CODE_DETAILS,
  GET_ALL_QR_CODE_BY_STATUS,
  DELETE_QR_CODE_URL,
  UPDATE_STATUS_QR_CODE_URL,
  ACTIVE_STATUS_QR_CODE_URL,
  DEACTIVE_STATUS_QR_CODE_URL,
} from "config/services";

class QrService {
  qrGenerate = (newQr) =>
    new Promise((resolve, reject) => {
      axios
        .post(GENERATE_QR_URL, newQr, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  //QR Code for Preview
  qrGeneratePreview = (newQrForPreview) =>
    new Promise((resolve, reject) => {
      axios
        .post(GENERATE_QR_ONLY_TO_PREVIEW_URL, newQrForPreview, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
            // console.log("qrGeneratePreview :preview",response?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  //get all qr code
  getAllQrCodes = () =>
    new Promise((resolve, reject) => {
      //
      axios
        .get(GET_ALL_QR_CODE_URL)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
            // console.log("qrGeneratePreview :preview",response?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  //get all qr code
  getAllQrCodesDetails = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_QR_CODE_DETAILS)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
            // console.log("qrGeneratePreview :preview",response?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  //get all qr code By status
  getAllQrCodesDetailsByStatus = (Status) =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_QR_CODE_BY_STATUS + Status)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
            // console.log("qrGeneratePreview :preview",response?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  // Delete  QR code  by Id
  deleteQrCode = (qrCodeId) =>
    new Promise((resolve, reject) => {
      axios
        .post(DELETE_QR_CODE_URL, qrCodeId)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  // Paused  QR code  by Id
  updateQrCode = (updateQrInfo) =>
    new Promise((resolve, reject) => {
      axios
        .post(UPDATE_STATUS_QR_CODE_URL, updateQrInfo)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
            // console.log("qrGeneratePreview :preview",response?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  // active  QR code  by Id
  activeQrCode = (activeQrInfo) =>
    new Promise((resolve, reject) => {
      axios
        .post(ACTIVE_STATUS_QR_CODE_URL, activeQrInfo)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
            // console.log("qrGeneratePreview :preview",response?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  // active  QR code  by Id
  deactiveQrCode = (deactiveQrInfo) =>
    new Promise((resolve, reject) => {
      axios
        .post(DEACTIVE_STATUS_QR_CODE_URL, deactiveQrInfo)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
            // console.log("qrGeneratePreview :preview",response?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  //GET QR code  by Id
  // getAllQrCodes = (userId) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .get(GET_ALL_QR_CODE_URL + userId)
  //       .then((response) => {
  //         if (response?.data?.error) {
  //           reject(response?.data?.error);
  //         } else {
  //           resolve(response?.data);
  //           // console.log("qrGeneratePreview :preview",response?.data)
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
}
const qrService = new QrService();

export default qrService;
