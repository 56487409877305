import { React, useState } from "react";
import {
  Box,
  Container,
  Toolbar,
  List,
  Divider,
  ListItemButton,
  ListItemText,
  ListItem,
} from "@mui/material";
import { useTheme } from "@emotion/react";

//import from react-router-dom
import { Outlet, useNavigate } from "react-router-dom";
//project import
import LOGO from "assets/images/img/mperito_logo.png";
// import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const MinimalLayout = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const drawerWidth = 240;
  // const handleClickSignIn = () => {
  //   navigate("/sign-in-page");
  // };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const sideBarData = [
    {
      id: 1,
      title: "Home",
      link: "/",
    },
    {
      id: 2,
      title: "Try Now",
      link: "/trial",
    },
    {
      id: 3,
      title: "Pricing",
      link: "/pricing",
    },
    {
      id: 4,
      title: "Sign Up / Sign In",
      link: "/sign-in",
    },
  ];
  const drawer = (
    <Box>
      <Toolbar>
        <Box
          sx={{
            width: drawerWidth,
            height: 60,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img
              alt="logo"
              src={LOGO}
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
            />
          </Box>
        </Box>
      </Toolbar>
      <Divider />
      <List sx={{ m: 1 }}>
        {sideBarData.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ my: 1 }}>
            <ListItemButton href={item.link}>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{ style: {
                  color: theme.palette.secondary.main,
                }}}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleClickSignUp = () => {
    navigate("/sign-in");
  };

  const handleClickPricing = () => {
    navigate("/pricing");
  };

  const handleClickTrial = () => {
    navigate("/trial");
  };

  const handleClickHome = () => {
    navigate("/");
  };

  //userId
  const userId = localStorage.getItem("userId");
  return (
    <Box
      sx={{
        bgcolor: theme.palette.primary.main,
        height: "100vh",
        backgroundSize: "cover",
        overflow: "auto",
      }}
    >
      <Header
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        logo={LOGO}
        handleClickHome={handleClickHome}
        userId={userId}
        handleClickPricing={handleClickPricing}
        handleClickSignUp={handleClickSignUp}
        handleClickTrial={handleClickTrial}
      />
      <Sidebar
        drawerWidth={drawerWidth}
        container={container}
        mobileOpen={mobileOpen}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        handleDrawerClose={handleDrawerClose}
        drawer={drawer}
      />
      <Container maxWidth="xl">
        <Box component="main" sx={{ pt: 12 }}>
          <Outlet />
        </Box>
      </Container>
      <Divider sx={{borderColor: theme.palette.secondary.main}}/>
      <Footer/>
    </Box>
  );
};

export default MinimalLayout;

