import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import authService from "services/authService";

// AuthGuard component to handle authentication
const AuthGuard = ({ isAuthRequired }) => {
  const auth = useSelector((state) => state?.auth);
  // console.log("AuthGuard:auth", auth);
  // if (isAuthRequired && !auth?.user) {
  //   return <Navigate to="/sign-in" />;
  // }
  if (isAuthRequired && !authService.isAuthenticated()) {
    return <Navigate to="/sign-in" />;
  }
  return <Outlet />;
};

AuthGuard.propTypes = {
  isAuthRequired: PropTypes.bool.isRequired,
};

export default AuthGuard;
