//Auth Services
export const SIGN_UP_URL = "/api/qr/auth/sign-up";
export const SEND_OTP_EMAIL_URL = "/api/qr/auth/signin/send-otp";
export const SIGN_IN_EMAIL_VERIFY_OTP_URL = "/api/qr/auth/validate-otp";
export const SIGN_OUT_URL = "/api/qr/auth/signout";
export const SIGN_IN_SILENT_URL = "/api/qr/auth/profile";
//Generate API
export const GENERATE_QR_URL = "/api/qr/add-user/generate-qr";
export const GENERATE_QR_ONLY_TO_PREVIEW_URL = "/api/qr/preview";
export const GET_ALL_QR_CODE_URL = "/api/qr/get";
export const DELETE_QR_CODE_URL = "/api/qr/delete";
export const UPDATE_STATUS_QR_CODE_URL = "/api/qr/update/status";
export const ACTIVE_STATUS_QR_CODE_URL =  "/api/qr/activate";
export const DEACTIVE_STATUS_QR_CODE_URL =  "/api/qr/deactivate";

//Subscription Plans API
export const GET_ALL_SUBSCRIPTION_PLAN_URL = "/api/qr/subscriptionPlans/get";
//Order API
export const BUY_SUBSCRIPTION_PLAN_URL = "/api/qr/orders/buy-subscription";
export const ORDER_VERIFY_URL = "/api/qr/orders/verify";
export const GET_ORDER_URL ="/api/qr/orders/get";
//Dashboard API
export const GET_ALL_QR_CODE_DETAILS = "/api/qr/dashboard/qrcode-details";
//Status API
export const GET_ALL_QR_CODE_BY_STATUS = "/api/qr/get/by-status?status=";
//Profile API
export const GET_PROFILE = "/api/qr/auth/getUser";
