import { useRoutes, Navigate } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import MinimalRoutes from "./MinimalRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MinimalRoutes,
    AuthenticationRoutes,
    MainRoutes,
    { path: "*", element: <Navigate replace to="/page-not-found" /> },
  ]);
}
