// assets
import {IconQrcode,IconQrcodeOff,IconListDetails} from "@tabler/icons-react"

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const myqrcodes = {
  id: "myqrcodes",
  title: "My QR Code",
  type: "group",
  children: [
    {
      id: "1",
      subtitle: "All QR Code",
      type: 'item',
      url: "/all-qr-code-generator",
      icon: <IconListDetails />,
    }, 
    {
      id: "2",
      subtitle: "Active QR Code",
      type: 'item',
      url: "/active-qr-code",
      icon: <IconQrcode />,
    },
    {
      id: "3",
      subtitle: "Paused QR Code",
      type: 'item',
      url: "/paused-qr-code",
      icon: <IconQrcodeOff />,
    },
    // {
    //   id: "4",
    //   title: "Expired QR Code",
    //   url: "/expired-qr-code",
    //   icon: <QrCodeIcon />,
    // },
  ],
};

export default myqrcodes;
