import dashboard from './dashboard';
import myqrcodes from './myqrcodes';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard,myqrcodes]
};

export default menuItems;