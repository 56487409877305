import React, { useEffect } from "react";
//import from  @mui/material
import {
  AppBar,
  Toolbar,
  IconButton,
  CardMedia,
  Card,
  Grid,
  Button,
  Container,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
//import from  prop-types
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "actions/authActions";

const Header = ({
  drawerWidth,
  handleDrawerToggle,
  logo,
  handleClickHome,
  userId,
  handleClickPricing,
  handleClickSignUp,
  handleClickTrial,
}) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  //user
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const profile = useSelector((state) => state?.auth?.user);

  useEffect(() => {}, [profile]);

  const profilePageNav = () => {
    navigate("/profile");
  };

  const signOutNavTOHomePage = () => {
    try {
      dispatch(signOut());
      localStorage.clear();
      navigate("/");
      window.location.reload();
    } catch (error) {
      // enqueueSnackbar("Unable to logout", {
      // console.log("it is not sign out");
      // });
    }
  };
  const settings = [
    {
      id: 1,
      title: "Profile",
      function: profilePageNav,
    },
    {
      id: 2,
      title: "Sign Out",
      function: signOutNavTOHomePage,
    },
  ];

  return (
    <AppBar
      position="fixed"
      sx={{
        // width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        boxShadow: 0,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6} sm={6} md={2}>
            <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "none",
                  bgcolor:theme.palette.primary.main,
                  borderRadius:"0px"
                  // p: "12px",
                }}
              >
                <CardMedia
                  component="img"
                  alt="logo"
                  image={logo}
                  sx={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={handleClickHome}
                />
              </Card>
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              {userId === false ? (
                <Grid
                  item
                  sx={{ display: { xs: "none", sm: "block", md: "block" } }}
                ></Grid>
              ) : (
                <Grid
                  item
                  sx={{ display: { xs: "none", sm: "block", md: "block" } }}
                >
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="text"
                        disableElevation
                        onClick={handleClickHome}
                      >
                        Home
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        disableElevation
                        onClick={handleClickTrial}
                      >
                        Try Now
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        disableElevation
                        onClick={handleClickPricing}
                      >
                        Pricing
                      </Button>
                    </Grid>
                    {/* <Grid item>
              <Button
                variant="contained"
                onClick={handleClickSignIn}
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  "&:hover": {
                    //you want this to be the same as the backgroundColor above
                    backgroundColor: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                  },
                }}
                disableElevation
              >
                Log In
              </Button>
            </Grid> */}
                    {!profile ? (
                      <Grid item>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={handleClickSignUp}
                          endIcon={<ArrowRightAltIcon />}
                          sx={{
                            "&:hover": {
                              //you want this to be the same as the backgroundColor above
                              // backgroundColor: `${theme.colors?.secondaryMain}`,
                              borderColor: theme.palette.secondary.main,
                            },
                          }}
                        >
                          Sign Up / Sign In
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Tooltip title="Open settings">
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={handleOpenUserMenu}
                          >
                            <Avatar
                              // alt="Remy Sharp"
                              sx={{
                                bgcolor: theme.palette.secondary.main,
                                color: "#ffffff",
                              }}
                            >
                              {profile?.name?.slice(0, 1)}
                            </Avatar>
                          </IconButton>
                        </Tooltip>
                        <Menu
                          sx={{ mt: "45px" }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          PaperProps={{
                            sx: { bgcolor: theme.palette.primary.main },
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          {settings.map((setting, index) => (
                            <MenuItem key={index} onClick={handleCloseUserMenu}>
                              <Button
                                sx={{ padding: "0px" }}
                                onClick={setting.function}
                                variant="text"
                                // textAlign="center"
                              >
                                {setting.title}
                              </Button>
                            </MenuItem>
                          ))}
                        </Menu>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Header.propTypes = {
  drawerWidth: PropTypes.number,
  handleDrawerToggle: PropTypes.func,
  logo: PropTypes.string,
  handleClickHome: PropTypes.func,
  userId: PropTypes.string,
  handleClickPricing: PropTypes.func,
  handleClickSignUp: PropTypes.func,
  handleClickTrial: PropTypes.func,
};

export default Header;
