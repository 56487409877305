import qrService from "services/qrService";
import {
  NewQrCodeReqInfo,
  PreviewQrCodeReqInfo,
} from "interface/createQrCodelf";

export const QR_GENERATE = "@qr/generate";
export const QR_GENERATE_SUCCESS = "@qr/generate-success";
export const QR_GENERATE_FAILURE = "@qr/generate-failure";

export const QR_GENERATE_PREVIEW = "@qr/preview";
export const QR_GENERATE_PREVIEW_SUCCESS = "@qr/preview-success";
export const QR_GENERATE_PREVIEW_FAILURE = "@qr/preview-failure";

export const GET_ALL_QR_CODE = "@qr/get-all";
export const GET_ALL_QR_CODE_SUCCESS = "@qr/get-all-success";
export const GET_ALL_QR_CODE_FAILURE = "@qr/get-all-failure";

export const GET_ALL_QR_CODE_DETAILS = "@qr/get-all-details";
export const GET_ALL_QR_CODE_DETAILS_SUCCESS = "@qr/get-all-details-success";
export const GET_ALL_QR_CODE_DETAILS_FAILURE = "@qr/get-all-details-failure";

export const GET_ALL_QR_CODE_BY_STATUS_DETAILS = "@qr/get-all-status-details";
export const GET_ALL_QR_CODE_BY_STATUS_DETAILS_SUCCESS =
  "@qr/get-all-status-details-success";
export const GET_ALL_QR_CODE_BY_STATUS_DETAILS_FAILURE =
  "@qr/get-all-status-details-failure";

export const GENERATE_QR_NEW_CODE = "@qr/generate_qr_code";

export const DELETE_QR_CODE = "@qr/delete-qrcode";
export const DELETE_QR_CODE_SUCCESS = "@qr/delete-qrcode-success";
export const DELETE_QR_CODE_FAILURE = "@qr/delete-qrcode-failure";

export const PAUSE_QR_CODE = "@qr/paused-qrcode";
export const PAUSE_QR_CODE_SUCCESS = "@qr/paused-qrcode-success";
export const PAUSE_QR_CODE_FAILURE = "@qr/paused-qrcode-failure";

export const ACTIVE_QR_CODE = "@qr/active-qrcode";
export const ACTIVE_QR_CODE_SUCCESS = "@qr/active-qrcode-success";
export const ACTIVE_QR_CODE_FAILURE = "@qr/active-qrcode-failure";

export const DEACTIVE_QR_CODE = "@qr/deactive-qrcode";
export const DEACTIVE_QR_CODE_SUCCESS = "@qr/deactive-qrcode-success";
export const DEACTIVE_QR_CODE_FAILURE = "@qr/deactive-qrcode-failure";

export function qrGenerate(qrInfo) {
  return async (dispatch) => {
    const newQrInfo = new NewQrCodeReqInfo(qrInfo);
    try {
      dispatch({
        type: QR_GENERATE,
        payload: {
          newQrInfo,
        },
      });
      const newCode = await qrService.qrGenerate(newQrInfo);
      // console.log("newCode", newCode);
      dispatch({
        type: QR_GENERATE_SUCCESS,
        payload: newCode,
      });
      // console.log("newCode", newCode);
    } catch (error) {
      dispatch({ type: QR_GENERATE_FAILURE });
      throw error;
    }
  };
}

//actions for create new QR Code
export function createNewQRCode() {
  return async (dispatch) => {
    dispatch({
      type: GENERATE_QR_NEW_CODE,
      payload: {},
    });
  };
}

//actions for Preview

export function qrPreview(qrInfo) {
  return async (dispatch) => {
    const newQrInfo = new PreviewQrCodeReqInfo(qrInfo);
    try {
      dispatch({
        type: QR_GENERATE_PREVIEW,
        payload: {
          newQrInfo,
        },
      });
      // console.log("newQrInfo:", newQrInfo);
      const previewData = await qrService.qrGeneratePreview(newQrInfo);
      // console.log("qrPreview.newQrInfo: Resp:" , previewData);
      dispatch({
        type: QR_GENERATE_PREVIEW_SUCCESS,
        payload: {
          ...previewData,
        },
      });
    } catch (error) {
      // console.log("AuthAction.signupMobile: Error", error);
      dispatch({ type: QR_GENERATE_PREVIEW_FAILURE });
      throw error;
    }
  };
}

export function getAllQrCodes() {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_QR_CODE,
      });
      // console.log("newQrInfo:", newQrInfo);
      const codes = await qrService.getAllQrCodes();
      // console.log("qrPreview.newQrInfo: Resp:" , previewData);
      dispatch({
        type: GET_ALL_QR_CODE_SUCCESS,
        payload: codes,
      });
    } catch (error) {
      // console.log("AuthAction.signupMobile: Error", error);
      dispatch({ type: GET_ALL_QR_CODE_FAILURE });
      throw error;
    }
  };
}

export function getAllQrCodesDetails() {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_QR_CODE_DETAILS,
      });
      // console.log("newQrInfo:", newQrInfo);
      const codesDetails = await qrService.getAllQrCodesDetails();
      // console.log("qrPreview.newQrInfo: Resp:" , previewData);
      dispatch({
        type: GET_ALL_QR_CODE_DETAILS_SUCCESS,
        payload: codesDetails,
      });
    } catch (error) {
      // console.log("AuthAction.signupMobile: Error", error);
      dispatch({ type: GET_ALL_QR_CODE_DETAILS_FAILURE });
      throw error;
    }
  };
}

export function getAllQrCodesStatusDetails(status) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_QR_CODE_BY_STATUS_DETAILS,
      });
      // console.log("newQrInfo:", newQrInfo);
      const codesStatusDetails = await qrService.getAllQrCodesDetailsByStatus(
        status
      );
      // console.log("qrPreview.newQrInfo: Resp:" , previewData);
      dispatch({
        type: GET_ALL_QR_CODE_BY_STATUS_DETAILS_SUCCESS,
        payload: codesStatusDetails,
      });
    } catch (error) {
      // console.log("AuthAction.signupMobile: Error", error);
      dispatch({ type: GET_ALL_QR_CODE_BY_STATUS_DETAILS_FAILURE });
      throw error;
    }
  };
}

// delete qr code code by Id
export function deleteQrCode(qrCodeId) {
  return async (dispatch) => {
    const status = "ACTIVE";
    const deleteQrCodeIdPayload = {
      qrCodeId: qrCodeId, // assuming qrInfo contains qrCodeId
    };
    try {
      dispatch({
        type: DELETE_QR_CODE,
      });
      const deleteQrCodeId = await qrService.deleteQrCode(
        deleteQrCodeIdPayload
      );
      dispatch({
        type: DELETE_QR_CODE_SUCCESS,
        payload: deleteQrCodeId,
      });
      // if (!status) {
      //   dispatch(getAllQrCodesStatusDetails(status));
      // } else {
      //   dispatch(getAllQrCodesDetails());
      //   dispatch(getAllQrCodes());
      // }
    } catch (error) {
      // console.log("AuthAction.signupMobile: Error", error);
      dispatch({ type: DELETE_QR_CODE_FAILURE });
      throw error;
    }
  };
}

// pause qr code code by Id
export function pauseQrCode(qrCodeId) {
  return async (dispatch) => {
    const userId = localStorage.getItem("userId");
    const status = "hold";
    const updateQrCodeIdPayload = {
      userId: userId,
      qrCodeId: qrCodeId, // assuming qrInfo contains qrCodeId
      status: status,
    };
    try {
      dispatch({
        type: PAUSE_QR_CODE,
      });
      const pausedQrCodeId = await qrService.updateQrCode(
        updateQrCodeIdPayload
      );
      dispatch({
        type: PAUSE_QR_CODE_SUCCESS,
        payload: pausedQrCodeId,
      });
      // dispatch(getAllQrCodesStatusDetails(status));
      // if (!status) {
      //   dispatch(getAllQrCodesStatusDetails(status));
      // } else {
      //   dispatch(getAllQrCodesDetails());
      // }
    } catch (error) {
      // console.log("AuthAction.signupMobile: Error", error);
      dispatch({ type: PAUSE_QR_CODE_FAILURE });
      throw error;
    }
  };
}

// active qr code code by Id
export function activeQrCode(qrCodeId) {
  return async (dispatch) => {
    const activeQrCodeIdPayload = {
      qrCodeId: qrCodeId, // assuming qrInfo contains qrCodeId
    };
    try {
      dispatch({
        type: ACTIVE_QR_CODE,
      });
      const activeQrCodeId = await qrService.activeQrCode(
        activeQrCodeIdPayload
      );
      dispatch({
        type: ACTIVE_QR_CODE_SUCCESS,
        payload: activeQrCodeId,
      });
      // dispatch(getAllQrCodesStatusDetails(status));
      // if (!status) {
      //   dispatch(getAllQrCodesStatusDetails(status));
      // } else {
      //   dispatch(getAllQrCodesDetails());
      // }
    } catch (error) {
      // console.log("AuthAction.signupMobile: Error", error);
      dispatch({ type: ACTIVE_QR_CODE_FAILURE });
      throw error;
    }
  };
}

// active qr code code by Id
export function deactiveQrCode(qrCodeId) {
  return async (dispatch) => {
    const activeQrCodeIdPayload = {
      qrCodeId: qrCodeId, // assuming qrInfo contains qrCodeId
    };
    try {
      dispatch({
        type: DEACTIVE_QR_CODE,
      });
      const deactiveQrCodeId = await qrService.deactiveQrCode(
        activeQrCodeIdPayload
      );
      dispatch({
        type: DEACTIVE_QR_CODE_SUCCESS,
        payload: deactiveQrCodeId,
      });
      // dispatch(getAllQrCodesStatusDetails(status));
      // if (!status) {
      //   dispatch(getAllQrCodesStatusDetails(status));
      // } else {
      //   dispatch(getAllQrCodesDetails());
      // }
    } catch (error) {
      // console.log("AuthAction.signupMobile: Error", error);
      dispatch({ type: ACTIVE_QR_CODE_FAILURE });
      throw error;
    }
  };
}
//get qr code code by Id
// export function getAllQrCodes(userIdInfo) {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: GET_ALL_QR_CODE,
//       });
//       // console.log("newQrInfo:", newQrInfo);
//       const userId = await qrService.getAllQrCodes(userIdInfo);
//       // console.log("qrPreview.newQrInfo: Resp:" , previewData);
//       dispatch({
//         type: GET_ALL_QR_CODE_SUCCESS,
//         payload: userId,

//       });
//     } catch (error) {
//       // console.log("AuthAction.signupMobile: Error", error);
//       dispatch({ type: GET_ALL_QR_CODE_FAILURE });
//       throw error;
//     }
//   };
// }
