class NewSubscriptionPlan {
  constructor(buy) {
    this.subscriptionPlanId = buy.subscriptionPlanId;
    this.description = buy.description;
    this.redirectUrl = buy.redirectUrl;
    this.isFromWeb = buy.isFromWeb;
    this.planDuration = buy.planDuration;
    this.useGSTIN = {
      GSTIN: buy.useGSTIN?.GSTIN,
      PAN: buy.useGSTIN?.PAN,
      businessName: buy.useGSTIN?.businessName,
    };
    this.billTo = {
      address: {
        name: buy.billTo?.address?.name,
        addressLine1: buy.billTo?.address?.addressLine1,
        addressLine2: buy.billTo?.address?.addressLine2,
        city: buy.billTo?.address?.city,
        state: buy.billTo?.address?.state,
        country: buy.billTo?.address?.country,
        pinCode: buy.billTo?.address?.pinCode,
        mobile: buy.billTo?.address?.mobile,
        email: buy.billTo?.address?.email,
      },
    };
  }
}

export default NewSubscriptionPlan;
