import authService from "services/authService";
import NewUserReqInfo from "interface/userProfileIf";

// import { UserProfile } from "interface/userProfileIf";

export const SIGN_IN = "@auth/sign-in";
export const SIGN_IN_SUCCESS = "@auth/sign-in-success";
export const SIGN_IN_FAILURE = "@auth/sign-in-failure";

export const SILENT_LOGIN = "@auth/silent-login";

export const SIGN_OUT = "@auth/sign-out";
export const SIGN_OUT_SUCCESS = "@auth/sign-out-success";
export const SIGN_OUT_FAILURE = "@auth/sign-out-failure";

export const SIGN_IN_MOBILE_REQ_OTP = "@auth/sign-in-email-req-otp";
export const SIGN_IN_MOBILE_REQ_OTP_SUCCESS =
  "@auth/sign-in-email-req-otp-success";
export const SIGN_IN_MOBILE_REQ_OTP_FAILURE =
  "@auth/sign-in-email-req-otp-failure";

export const SIGN_IN_EMAIL_REQ_OTP = "@auth/sign-in-email-req-otp";
export const SIGN_IN_EMAIL_REQ_OTP_SUCCESS =
  "@auth/sign-in-email-req-otp-success";
export const SIGN_IN_EMAIL_REQ_OTP_FAILURE =
  "@auth/sign-in-email-req-otp-failure";

export const SIGN_IN_EMAIL_OTP_VERIFY = "@auth/sign-in-verify-otp";
export const SIGN_IN_EMAIL_OTP_VERIFY_SUCCESS =
  "@auth/sign-in-verify-otp-success";
export const SIGN_IN_EMAIL_OTP_VERIFY_FAILURE =
  "@auth/sign-in-verify-otp-failure";

export const SIGNUP = "@auth/sign-up";
export const SIGNUP_SUCCESS = "@auth/sign-up-success";
export const SIGNUP_FAILURE = "@auth/sign-up-failure";

export const SIGNUP_MOBILE_VERIFY_OTP = "@auth/sign-up-verify-otp";
export const SIGNUP_MOBILE_VERIFY_OTP_SUCCESS =
  "@auth/sign-up-verify-otp-success";
export const SIGNUP_MOBILE_VERIFY_OTP_FAILURE =
  "@auth/sign-up-verify-otp-failure";

export const GET_PROFILE = "@auth/get-profile";
export const GET_PROFILE_SUCCESS = "@auth/get-profile-success";
export const GET_PROFILE_FAILURE = "@auth/get-profile-failure";

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    });
}

export function sendOTPOnEmail(email) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SIGN_IN_EMAIL_REQ_OTP,
        payload: {
          email,
        },
      });
      const user = await authService.sendOtpOnEmail(email);
      dispatch({
        type: SIGN_IN_EMAIL_REQ_OTP_SUCCESS,
        payload: {
          user,
        },
      });
    } catch (error) {
      dispatch({ type: SIGN_IN_EMAIL_REQ_OTP_FAILURE });

      throw error;
    }
  };
}

export function signUp(userInfo) {
  return async (dispatch) => {
    const newUserInfo = new NewUserReqInfo(userInfo);
    try {
      dispatch({
        type: SIGNUP,
        payload: {
          newUserInfo,
        },
      });
      const user = await authService.signUp(newUserInfo);
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: {
          user,
        },
      });
    } catch (error) {
      dispatch({
        type: SIGNUP_FAILURE,
        payload: error,
      });
      throw error;
    }
  };
}

export function signInEmailVerifyOTP(email, otp) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SIGN_IN_EMAIL_OTP_VERIFY,
        payload: {
          email,
        },
      });

      const user = await authService.signInEmailVerifyOTP(email, otp);
      dispatch({
        type: SIGN_IN_EMAIL_OTP_VERIFY_SUCCESS,
        payload: {
          user,
        },
      });
      return user;
    } catch (error) {
      if (error.errorCode === "VALID_OTP_NO_USER") {
        return null;
      }
      dispatch({
        type: SIGN_IN_EMAIL_OTP_VERIFY_FAILURE,
        payload: error,
      });

      throw error;
    }
  };
}

export function signOut() {
  return async (dispatch) => {
    authService.signOut();
    dispatch({
      type: SIGN_OUT,
    });
  };
}

export function getProfile() {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PROFILE,
      });
      // console.log("newQrInfo:", newQrInfo);
      const profile = await authService.getProfile();
      dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: profile,
      });
    } catch (error) {
      // console.log("AuthAction.signupMobile: Error", error);
      dispatch({ type: GET_PROFILE_FAILURE });
      throw error;
    }
  };
}
