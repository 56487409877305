import { produce } from "immer";

import {
  SIGNUP_SUCCESS,
  SIGN_IN_EMAIL_REQ_OTP,
  SIGN_IN_EMAIL_OTP_VERIFY,
  SIGN_IN_EMAIL_OTP_VERIFY_SUCCESS,
  SIGN_IN_EMAIL_OTP_VERIFY_FAILURE,
  SILENT_LOGIN,
} from "../actions/authActions";

const initialState = {
  user: null,
  signInEmail: null,
  signInVerifyEmail: null,
  profile: null,
  loading: false,
  errorSignUp: null,
  errorReqOtp: null,
  errorVerifyOtp: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    //Email OTP  actions
    case SIGN_IN_EMAIL_REQ_OTP: {
      const { email } = action.payload;
      return produce(state, (draft) => {
        draft.loading = true;
        draft.errorReqOtp = null;
        draft.signInEmail = email;
        draft.user = null;
        draft.signInVerifyEmail = null;
        draft.profile = null;
        draft.loading = false;
        draft.errorSignUp = null;
        draft.errorReqOtp = null;
        draft.errorVerifyOtp = null;
      });
    }
    //Email OTP  actions
    case SIGN_IN_EMAIL_OTP_VERIFY: {
      const { email } = action.payload;
      return produce(state, (draft) => {
        draft.loading = true;
        draft.errorVerifyOtp = null;
        draft.signInVerifyEmail = email;
      });
    }
    case SIGNUP_SUCCESS:
    case SILENT_LOGIN:
    case SIGN_IN_EMAIL_OTP_VERIFY_SUCCESS: {
      const { user } = action.payload;
      return produce(state, (draft) => {
        draft.loading = false;
        draft.user = user;
        draft.profile = user;
      });
    }
    case SIGN_IN_EMAIL_OTP_VERIFY_FAILURE: {
      const error = action.payload;
      return produce(state, (draft) => {
        draft.loading = false;
        draft.errorVerifyOtp = error;
      });
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
