import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import authReducer from "./authReducer";
import qrcodeGenerateReducer from "./qrcodeGenerateReducer";
import subscriptionPlansReducer from "./subscriptionPlansReducer";


const rootReducer = combineReducers({
  customization: customizationReducer,
  auth: authReducer,
  qrcodeGenerate:qrcodeGenerateReducer,
  subscriptionPlans: subscriptionPlansReducer
});

export default rootReducer;
