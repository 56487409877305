import React from "react";

import { Fab } from "@mui/material";
import { useTheme } from "@emotion/react";

import { IconPlus } from "@tabler/icons-react";


const BlinkingFloatingButton = ({ createQrCodePageNav }) => {

    const theme = useTheme();
  // const [show, setShow] = useState(true);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setShow((prev) => !prev);
  //   }, 3000); // Toggle visibility every 1000ms (1 second)

  //   return () => clearInterval(interval); // Cleanup on component unmount
  // }, []);

  return (
    // <Grow
    //   in={show}
    //   style={{ transformOrigin: "center",transition: 'transform  ease'  }}
    //   timeout={1500} // Duration of the grow animation
    // >
      <Fab
        variant="extended"
        size="medium"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1000,
          bgcolor: theme.palette.secondary.main,
          color: theme.palette.secondary.light,
          "&:hover": {
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.secondary.light,
          },
        }}
        onClick={createQrCodePageNav}
      >
        <IconPlus style={{ marginRight: "10px" }} />
        New QR
      </Fab>
    // </Grow>
  );
};

export default BlinkingFloatingButton;
