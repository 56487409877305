// assets
import { IconDashboard, IconPaperBag } from "@tabler/icons-react";
// import IconQRCodeCreate from "assets/images/icons/IconQrCreate";
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "default",
      subtitle: "Dashboard",
      type: "item",
      url: "/dashboard/default",
      icon: <IconDashboard />,
      breadcrumbs: false,
    },
    {
      id: "1",
      subtitle: "Orders",
      type: "item",
      url: "/orders",
      icon: <IconPaperBag />,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
