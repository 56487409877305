export const REACT_APP_NAME = "Mperito QR App";
export const APP_VERSION = "1.0.0";
export const API_BASE_URL = "/api";
export const ENABLE_REDUX_LOGGER = false;

export const SIGN_UP_COUNTER = 34;
export const SIGN_IN_COUNTER = 34;
export const VERIFIED_DISPLAY_COUNTER = 30;

export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL || "";
//window.location.hostname;
export const QR_PREVIEW_IMAGE = "/assets/qr_preview.png";
export default {};
