

class NewUserReqInfo {
  constructor(userInfo) {
      this.email = userInfo.email;
      this.mobile = userInfo.mobile;
      this.name = userInfo.name;
      
  }
};

export default  NewUserReqInfo ;;
