import { React } from "react";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";

//import from react-router-dom
import { Outlet } from "react-router-dom";

import LOGO_COLOR from "assets/images/img/mperito_color_logo.png";

const AuthLayout = () => {
  return (
    <Container maxWidth="xs">
      <Box sx={{ height: "100vh", display: "flex" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          component="main"
        >
          <Grid item>
            <Card
              sx={{
                maxWidth: 345,
                borderRadius: "0px",
              }}
            >
              <CardMedia
                component="img"
                height="64"
                image={LOGO_COLOR}
                alt="LOGO_COLOR"
              />
            </Card>
          </Grid>
          <Grid item pt={2}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AuthLayout;
