import React from "react";
//import from  @mui/material
import { Box, Drawer } from "@mui/material";
//import from  prop-types
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";

const Sidebar = ({
  drawerWidth,
  container,
  mobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
  drawer,
}) => {
  const theme = useTheme();
  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: theme.palette.primary.main,
          },
        }}
      >
        {drawer}
      </Drawer>
      {/* <Drawer
        variant="permanent"
        sx={{
          display: { xs: "block", sm: "none" ,md:"none"},
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer> */}
    </Box>
  );
};

Sidebar.propTypes = {
  drawerWidth: PropTypes.number,
  container: PropTypes.func,
  mobileOpen: PropTypes.func,
  handleDrawerTransitionEnd: PropTypes.func,
  handleDrawerClose: PropTypes.func,
  drawer: PropTypes.func,
};

export default Sidebar;
