import NewSubscriptionPlan from "interface/subscriptionPlan";
import subscriptionService from "services/subscriptionService";

export const BUY_SUBSCRIPTION_PLAN = "@subscription/buy-subscription";
export const BUY_SUBSCRIPTION_PLAN_SUCCESS =
  "@subscription/buy-subscription-success";
export const BUY_SUBSCRIPTION_PLAN_FAILURE =
  "@subscription/buy-subscription-failure";

export const GET_ALL_SUBSCRIPTION_PLAN = "@subscription/get-all";
export const GET_ALL_SUBSCRIPTION_PLAN_SUCCESS =
  "@subscription/get-all-success";
export const GET_ALL_SUBSCRIPTION_PLAN_FAILURE =
  "@subscription/get-all-failure";

export const VERIFY_SUBSCRIPTION_PLAN = "@subscription/verify-plan";
export const VERIFY_SUBSCRIPTION_PLAN_SUCCESS =
  "@subscription/verify-plan-success";
export const VERIFY_SUBSCRIPTION_PLAN_FAILURE =
  "@subscription/verify-plan-failure";

export const GET_ALL_ORDERS = "@subscription/get-all-orders";
export const GET_ALL_ORDERS_SUCCESS = "@subscription/get-all-orders-success";
export const GET_ALL_ORDERS_FAILURE = "@subscription/get-all-orders-failure";

export function buySubscription(buy) {
  return async (dispatch) => {
    const newSubscriptionInfo = new NewSubscriptionPlan(buy);
    try {
      dispatch({
        type: BUY_SUBSCRIPTION_PLAN,
        payload: {
          newSubscriptionInfo,
        },
      });
      const subscription = await subscriptionService.buySubscription(
        newSubscriptionInfo
      );
      dispatch({
        type: BUY_SUBSCRIPTION_PLAN_SUCCESS,
        payload: {
          subscription,
        },
      });
      return subscription;
    } catch (error) {
      dispatch({ type: BUY_SUBSCRIPTION_PLAN_FAILURE });
      throw error;
    }
  };
}

export function getAllSubscriptionPlans() {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_SUBSCRIPTION_PLAN,
      });
      const subscribe = await subscriptionService.getAllSubscriptionPlan();
      dispatch({
        type: GET_ALL_SUBSCRIPTION_PLAN_SUCCESS,
        payload: subscribe,
      });
    } catch (error) {
      dispatch({ type: GET_ALL_SUBSCRIPTION_PLAN_FAILURE });
      throw error;
    }
  };
}

export function verifySubscription(verifyPayment) {
  return async (dispatch) => {
    // const newSubscriptionInfo = new NewSubscriptionPlan(verifyPayment);
    try {
      dispatch({
        type: VERIFY_SUBSCRIPTION_PLAN,
        payload: {
          verifyPayment,
        },
      });
      const verifySubscription =
        await subscriptionService.verifySubscriptionPlan(verifyPayment);
      dispatch({
        type: VERIFY_SUBSCRIPTION_PLAN_SUCCESS,
        payload: {
          verifySubscription,
        },
      });
    } catch (error) {
      dispatch({ type: VERIFY_SUBSCRIPTION_PLAN_FAILURE });
      throw error;
    }
  };
}

export function getAllOrders() {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_ORDERS,
      });
      const orders = await subscriptionService.getAllOrders();
      dispatch({
        type: GET_ALL_ORDERS_SUCCESS,
        payload: orders,
      });
      
    } catch (error) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS });
      throw error;
    }
  };
}
