import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "ui-component/AuthGuard";

//utilles routing
const MyQRCodePage = Loadable(lazy(() => import("views/dashboard/MyQRCode")));
const ActiveQRCode = Loadable(
  lazy(() => import("views/dashboard/ActiveQRCode"))
);
const PausedQRCode = Loadable(
  lazy(() => import("views/dashboard/PausedQRCode"))
);
const ExpiredQRCode = Loadable(
  lazy(() => import("views/dashboard/ExpiredQRCode"))
);
const DashBoardPage = Loadable(lazy(() => import("views/dashboard/Dashboard")));
const Profile = Loadable(lazy(() => import("views/dashboard/Profile")));
//Create Qr Code routing
const CreateQRCodePage = Loadable(
  lazy(() => import("views/dashboard/MyQRCode/CreateQrCode"))
);
//Order Qr Code routing
const OrdersPage = Loadable(lazy(() => import("views/dashboard/Orders")));

//Order  Details Qr Code routing
const OrdersDetailsPage = Loadable(
  lazy(() => import("views/dashboard/OrderDetails"))
);
//Order  Details  PDF Qr Code routing
const OrdersDetailsPDFPage = Loadable(
  lazy(() => import("views/dashboard/InvoicePdf"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      element: <AuthGuard isAuthRequired={true} />, // Apply authentication check to all child routes
      children: [
        {
          path: "/dashboard/default",
          element: <DashBoardPage />,
        },
        {
          path: "/all-qr-code-generator",
          element: <MyQRCodePage />,
        },
        {
          path: "/create-qr-code-generator",
          element: <CreateQRCodePage />,
        },
        {
          path: "/active-qr-code",
          element: <ActiveQRCode />,
        },
        {
          path: "/paused-qr-code",
          element: <PausedQRCode />,
        },
        {
          path: "/expired-qr-code",
          element: <ExpiredQRCode />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/orders",
          element: <OrdersPage />,
        },
        {
          path: "/orders-details",
          element: <OrdersDetailsPage />,
        },
        {
          path: "/order-invoice-pdf",
          element: <OrdersDetailsPDFPage />,
        },
      ],
    },
  ],
};

export default MainRoutes;
