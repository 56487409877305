import { produce } from "immer";

import {
  BUY_SUBSCRIPTION_PLAN,
  BUY_SUBSCRIPTION_PLAN_SUCCESS,
  BUY_SUBSCRIPTION_PLAN_FAILURE,
  VERIFY_SUBSCRIPTION_PLAN,
  VERIFY_SUBSCRIPTION_PLAN_FAILURE,
  VERIFY_SUBSCRIPTION_PLAN_SUCCESS,
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_FAILURE,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_SUBSCRIPTION_PLAN,
  GET_ALL_SUBSCRIPTION_PLAN_FAILURE,
  GET_ALL_SUBSCRIPTION_PLAN_SUCCESS,
} from "../actions/subscriptionAction";

const initialState = {
  buySubscriptionPlans: [],
  subscriptionPlans: [],
  verifyPayment: [],
  orders: [],
};

const subscriptionPlansReducer = (state = initialState, action) => {
  switch (action.type) {
    //qr Buy Subscription Plans
    case BUY_SUBSCRIPTION_PLAN: {
      return produce(state, (draft) => {
        draft.buySubscriptionPlans = [];
      });
    }

    case BUY_SUBSCRIPTION_PLAN_SUCCESS: {
      const buySubscriptionPlans = action.payload;
      return produce(state, (draft) => {
        draft.buySubscriptionPlans = buySubscriptionPlans;
      });
    }

    case BUY_SUBSCRIPTION_PLAN_FAILURE: {
      return produce(state, (draft) => {});
    }

    //qr get All Subscription Plans
    case GET_ALL_SUBSCRIPTION_PLAN: {
      return produce(state, (draft) => {
        draft.subscriptionPlans = [];
      });
    }

    case GET_ALL_SUBSCRIPTION_PLAN_SUCCESS: {
      const subscriptionPlans = action.payload;
      return produce(state, (draft) => {
        draft.subscriptionPlans = subscriptionPlans;
      });
    }

    case GET_ALL_SUBSCRIPTION_PLAN_FAILURE: {
      return produce(state, (draft) => {});
    }

    //qr get All Subscription Plans
    case VERIFY_SUBSCRIPTION_PLAN: {
      return produce(state, (draft) => {
        draft.verifyPayment = [];
      });
    }

    case VERIFY_SUBSCRIPTION_PLAN_SUCCESS: {
      const verifyPayment = action.payload;
      return produce(state, (draft) => {
        draft.verifyPayment = verifyPayment;
      });
    }

    case VERIFY_SUBSCRIPTION_PLAN_FAILURE: {
      return produce(state, (draft) => {});
    }

    //qr get All Orders
    case GET_ALL_ORDERS: {
      return produce(state, (draft) => {
        draft.verifyPayment = [];
      });
    }

    case GET_ALL_ORDERS_SUCCESS: {
      const orders = action.payload;
      return produce(state, (draft) => {
        draft.orders = orders;
      });
    }

    case GET_ALL_ORDERS_FAILURE: {
      return produce(state, (draft) => {});
    }
    default:
      // eslint-disable-next-line no-lone-blocks
      {
        return state;
      }
      break;
  }
};

export default subscriptionPlansReducer;
