class NewQrCodeReqInfo {
  constructor(qrInfo) {
    this.email = qrInfo.email;
    this.logo = qrInfo.logo;
    this.redirectionUrl = qrInfo.url;
    this.docFile = qrInfo.PDF;
    this.qrType = qrInfo.qrType;
    this.width = qrInfo.width;
    this.height = qrInfo.height;
    this.qrColor = qrInfo.color;
    this.bgColor = qrInfo.bgcolor;
  }
}

class PreviewQrCodeReqInfo {
  constructor(qrInfo) {
    this.email = qrInfo.email;
    this.logo = qrInfo.logo;
    this.redirectionUrl = qrInfo.url;
    this.qrType = qrInfo.qrType;
    this.width = qrInfo.width;
    this.height = qrInfo.height;
    this.qrColor = qrInfo.color;
    this.bgColor = qrInfo.bgcolor;
  }
}

export default NewQrCodeReqInfo;

export { NewQrCodeReqInfo, PreviewQrCodeReqInfo };
