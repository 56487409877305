import { useTheme } from "@emotion/react";
import { Button, Grid, Typography } from "@mui/material";
import { React } from "react";

const Footer = () => {

  const  theme = useTheme();
  
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      display="flex"
      pt={10}
    >
      <Grid
        item
        py={3}
        xs={12}
        sm={12}
        md={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          href={"/privacy-policy"}
          sx={{
            color: "text.main",
            "&:hover": {
              backgroundColor: "primary.main",
              // opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          Privacy Policy
        </Button>
      </Grid>
      {/* <Grid
        item
        py={3}
        xs={12}
        sm={12}
        md={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          href={"/shipping-policy"}
          sx={{
            color: "text.main",
            "&:hover": {
              backgroundColor: "primary.main",
              // opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          Shipping Policy
        </Button>
      </Grid> */}
      {/* <Grid
        item
        py={3}
        xs={12}
        sm={12}
        md={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          href={"/return-policy"}
          sx={{
            color: "text.main",
            "&:hover": {
              backgroundColor: "primary.main",
              // opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          Return Policy
        </Button>
      </Grid> */}
      <Grid
        item
        py={3}
        xs={12}
        sm={12}
        md={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          href={"/refund-policy"}
          sx={{
            color: "text.main",
            "&:hover": {
              backgroundColor: "primary.main",
              // opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          Refund Policy
        </Button>
      </Grid>
      <Grid
        item
        py={3}
        xs={12}
        sm={12}
        md={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          href={"/terms-and-conditions"}
          sx={{
            color: "text.main",
            "&:hover": {
              backgroundColor: "primary.main",
              // opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          Terms and Conditions
        </Button>
      </Grid>


      <Grid
        item
        py={3}
        xs={12}
        sm={12}
        md={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          href={"/support"}
          sx={{
            color: "text.main",
            "&:hover": {
              backgroundColor: "primary.main",
              // opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          Support
        </Button>
      </Grid>
      <Grid item py={3} xs={12} sx={{ backgroundColor: "primary.dark" }}>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ color: theme.palette.secondary.main}}
        >
          Copyright © 2023 All rights reserved by MindsClik Digital Solutions
          Pvt. Ltd.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;