import { produce } from "immer";

import {
  QR_GENERATE,
  QR_GENERATE_SUCCESS,
  QR_GENERATE_FAILURE,
  QR_GENERATE_PREVIEW,
  QR_GENERATE_PREVIEW_SUCCESS,
  QR_GENERATE_PREVIEW_FAILURE,
  GET_ALL_QR_CODE,
  GET_ALL_QR_CODE_SUCCESS,
  GET_ALL_QR_CODE_FAILURE,
  GET_ALL_QR_CODE_DETAILS,
  GET_ALL_QR_CODE_DETAILS_FAILURE,
  GET_ALL_QR_CODE_DETAILS_SUCCESS,
  GET_ALL_QR_CODE_BY_STATUS_DETAILS,
  GET_ALL_QR_CODE_BY_STATUS_DETAILS_FAILURE,
  GET_ALL_QR_CODE_BY_STATUS_DETAILS_SUCCESS,
  GENERATE_QR_NEW_CODE,
} from "../actions/qrAction";

const initialState = {
  preview: "",
  generatepreview: [],
  qrCodes: [],
  qrCodesDetails: [],
  qrCodesDetailsByStatus: [],
};

const qrcodeGenerateReducer = (state = initialState, action) => {
  switch (action.type) {
    //QR Genrate Preview
    case QR_GENERATE: {
      return produce(state, (draft) => {
        draft.generatepreview = [];
      });
    }

    case QR_GENERATE_SUCCESS: {
      const preview = action.payload;
      return produce(state, (draft) => {
        draft.generatepreview = { ...preview };
      });
    }
    case QR_GENERATE_FAILURE: {
      return produce(state, (draft) => {});
    }

    //QR Genrate Preview
    case QR_GENERATE_PREVIEW: {
      return produce(state, (draft) => {
        draft.preview = [];
      });
    }

    case QR_GENERATE_PREVIEW_SUCCESS: {
      const preview = action.payload;
      return produce(state, (draft) => {
        draft.preview = { ...preview };
      });
    }
    case QR_GENERATE_PREVIEW_FAILURE: {
      return produce(state, (draft) => {});
    }
    //clear form
    case GENERATE_QR_NEW_CODE: {
      return produce(state, (draft) => {
        draft.generatepreview = undefined;
      });
    }
    //qr get All Qr Code
    case GET_ALL_QR_CODE: {
      return produce(state, (draft) => {
        draft.qrCodes = [];
      });
    }

    case GET_ALL_QR_CODE_SUCCESS: {
      const qrCodes = action.payload;
      return produce(state, (draft) => {
        draft.qrCodes = qrCodes;
      });
    }

    case GET_ALL_QR_CODE_FAILURE: {
      return produce(state, (draft) => {});
    }
    //qr get All Qr Codes Details
    case GET_ALL_QR_CODE_DETAILS: {
      return produce(state, (draft) => {
        draft.qrCodesDetails = [];
      });
    }

    case GET_ALL_QR_CODE_DETAILS_SUCCESS: {
      const qrCodesDetails = action.payload;
      return produce(state, (draft) => {
        draft.qrCodesDetails = qrCodesDetails;
      });
    }

    case GET_ALL_QR_CODE_DETAILS_FAILURE: {
      return produce(state, (draft) => {});
    }
    //qr get All Qr Codes Details using status
    case GET_ALL_QR_CODE_BY_STATUS_DETAILS: {
      return produce(state, (draft) => {
        draft.qrCodesDetailsByStatus = [];
      });
    }

    case GET_ALL_QR_CODE_BY_STATUS_DETAILS_SUCCESS: {
      const qrCodesDetailsByStatus = action.payload;
      // console.log("GET_ALL_QR_CODE_BY_STATUS_DETAILS_SUCCESS :qrCodesDetailsByStatus", action.payload);
      return produce(state, (draft) => {
        draft.qrCodesDetailsByStatus = qrCodesDetailsByStatus;
      });
    }

    case GET_ALL_QR_CODE_BY_STATUS_DETAILS_FAILURE: {
      return produce(state, (draft) => {});
    }
    default:
      // eslint-disable-next-line no-lone-blocks
      {
        return state;
      }
      break;
  }
};

export default qrcodeGenerateReducer;
